import { combineReducers } from 'redux';

import ProjectsReducer from './projectsReducer';
import TasksReducer from './tasksReducer';
import accountReducer from './accountReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  projects: ProjectsReducer,
  tasks: TasksReducer,
});

export default rootReducer;
