/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import {
  AppBar, Avatar, Button, Grid, IconButton, makeStyles, Menu, MenuItem, Switch, TextField, Toolbar,
  Typography
} from '@material-ui/core';
import { ExitToApp, MoreVert, Person } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import { useAuth } from './AuthProvider';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: '10em',
    marginRight: '10px'
  },
  sectionDesktop: {
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  title: {
    width: '80%',
    marginLeft: '10%',
  },
  userButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.5rem',
    textTransform: 'none',
  },
  userName: {
    paddingLeft: '0.5rem',
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  linkColor: {
    color: 'black',
    textDecoration: 'none',
  },
}));

function Navbar({ searchQuery, setSearchQuery }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authLogout } = useAuth();

  const userDetails = useSelector((state) => state.account.userDetails);

  const [imageUrl, setImageUrl] = useState('');
  const [userName, setUserName] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const mobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    // retrieve user image and name from local storage
    const savedUserImageUrl = localStorage.getItem('userImageUrl');
    const savedUserName = localStorage.getItem('userName');

    // set user image and name to state
    setImageUrl(savedUserImageUrl);
    setUserName(savedUserName);
  }, [userDetails]);

  const handleLogout = () => {
    authLogout();

    // when open or close the app, reuen the projects to be the default choice
    localStorage.setItem('selectedIndex', 0);

    navigate('/auth', { replace: true });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="account-menu-mobile"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={mobileMenuOpen}
      onClose={handleMobileClose}
    >
      <MenuItem>
        <Person />
        {' '}
        Profile
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ExitToApp />
        {' '}
        Log out
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      style={{
        backgroundColor: 'white', color: 'black', height: '8vh', paddingTop: '0.7em'
      }}
      position="static"
    >
      <Toolbar>
        <Grid container>
          <Grid item xs={3} lg={3} md={3}>
            <img src={logo} alt="logo" className={classes.logo} />
          </Grid>
          <Grid item xs={6} lg={6} md={6}>
            <TextField className={classes.title} id="search" value={searchQuery} placeholder="Search for anything..." variant="outlined" onChange={handleSearchChange} />
          </Grid>
          <Grid item xs={3} lg={3} md={3}>
            <div
              className={classes.sectionDesktop}
            >
              <Button
                className={classes.userButton}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <Avatar alt="userLogo" src={imageUrl} />
                <Typography className={classes.userName}>
                  {userName}
                </Typography>
              </Button>
            </div>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              keepMounted
              transformOrigin={{ vertical: 'center', horizontal: 'left' }}
              open={open}
              onClose={handleClose}
            >
              <Link className={classes.linkColor} to="/profile">
                <MenuItem>
                  <Person />
                  {' '}
                  Profile
                </MenuItem>
              </Link>
              <MenuItem onClick={handleLogout}>
                <ExitToApp />
                {' '}
                Log out
              </MenuItem>
            </Menu>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls="account-menu-mobile"
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <Avatar alt="userLogo" src={imageUrl} />
                <MoreVert />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
}

// Define prop types for Navbar component
Navbar.propTypes = {
  searchQuery: PropTypes.string.isRequired, // Expecting a string for searchQuery
  setSearchQuery: PropTypes.func.isRequired, // Expecting a function for setSearchQuery
};

export default Navbar;
