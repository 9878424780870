import axios from 'axios';
import { API_ROUTES } from '.';

export const getTasks = async (token, page, project = '', name = '') => {
  let response;
  try {
    response = await axios({
      url: API_ROUTES.tasks['tasks:get'],
      method: 'GET',
      headers: { Authorization: `Bearer ${token}`, 'Content-Language': 'en_US' },
      params: {
        page,
        limit: 20,
        name,
        project
      }
    });
  } catch (error) {
    console.log('get tasks error');
    response = error;
  }

  return response;
};

export const getTaskDetails = async (token, taskId) => {
  let response;
  try {
    response = await axios({
      url: API_ROUTES.tasks['taskDetails:get'](taskId),
      method: 'GET',
      headers: { Authorization: `Bearer ${token}`, 'Content-Language': 'en_US' },
    });
  } catch (error) {
    console.log('get task details error');
    response = error;
  }

  return response;
};

export const getTaskAttachment = async (token, attachmentURL) => {
  let response;
  try {
    response = await axios({
      url: API_ROUTES.tasks['taskAttachments:get'](attachmentURL),
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    });
  } catch (error) {
    console.log('get task attachment error');
    response = error;
  }

  return response;
};

export const postTaskComment = async (token, taskId, comment) => {
  let response;
  try {
    response = await axios({
      url: API_ROUTES.tasks['taskComment:post'](taskId),
      method: 'POST',
      headers: { Authorization: `Bearer ${token}`, 'Content-Language': 'en_US' },
      data: {
        comment,
      },
    });
  } catch (error) {
    console.log('post task comment error');
    response = error;
  }

  return response;
};

export const postTaskAttachment = async (token, taskId, attachment, fileName) => {
  let response;
  try {
    response = await axios({
      url: API_ROUTES.tasks['taskAttachment:post'](taskId),
      method: 'POST',
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Language': 'en_US',
        'Content-Type': 'text/plain',
        Attachment: fileName,
      },
      data: attachment,
    });
  } catch (error) {
    console.log('post task attachment error');
    response = error;
  }

  return response;
};

export const postTaskAction = async (token, taskId, actionType) => {
  let response;
  try {
    response = await axios({
      url: API_ROUTES.tasks['taskAction:post'](taskId),
      method: 'POST',
      headers: { Authorization: `Bearer ${token}`, 'Content-Language': 'en_US' },
      data: {
        action: actionType,
      },
    });
  } catch (error) {
    console.log('post task action error');
    response = error;
  }

  return response;
};

export default {};
