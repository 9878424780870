export const GET_TASKS = 'GET_TASKS';
export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const GET_TASK_ID = 'GET_TASK_ID';
export const GET_ATTACHMENT_URL = 'GET_ATTACHMENT_URL';
export const GET_TASK_COMMENT = 'GET_TASK_COMMENT';
export const GET_TASK_ATTACHMENT = 'GET_TASK_ATTACHMENT';
export const GET_TASK_ACTION = 'GET_TASK_ACTION';

export const saveTasks = (tasks) => ({
  type: GET_TASKS,
  payload: tasks,
});

export const saveTaskDetails = (taskDetails) => ({
  type: GET_TASK_DETAILS,
  payload: taskDetails,
});

export const saveTaskId = (taskId) => ({
  type: GET_TASK_ID,
  payload: taskId,
});

export const saveTaskAttachmentURL = (attachmentURL) => ({
  type: GET_ATTACHMENT_URL,
  payload: attachmentURL,
});

export const saveTaskComment = (taskComment) => ({
  type: GET_TASK_COMMENT,
  payload: taskComment,
});

export const saveTaskAttachment = (taskAttachment) => ({
  type: GET_TASK_ATTACHMENT,
  payload: taskAttachment,
});

export const saveTaskAction = (taskAction) => ({
  type: GET_TASK_ACTION,
  payload: taskAction,
});
