// TODO: make it static url for now
// const { BASE_URL } = process.env;
export const BASE_URL = 'https://dmc-ksa-dev-16251340.dev.odoo.com';

export const API_ROUTES = {
  account: {
    'token:get': `${BASE_URL}/v1/auth/token`,
    'revokeToken:post': `${BASE_URL}/v1/auth/revoke/token`,
    'refreshToken:get': `${BASE_URL}/v1/auth/refresh/token`,
    'dashboard:get': `${BASE_URL}/v1/me/dashboard`,
  },
  projects: {
    'projects:get': `${BASE_URL}/v1/me/projects`,
    'projectTasks:get': (projectId) => `${BASE_URL}/v1/me/${projectId}/tasks`,
  },
  tasks: {
    'tasks:get': `${BASE_URL}/v1/me/tasks`,
    'taskDetails:get': (taskId) => `${BASE_URL}/v1/me/task/${taskId}`,
    'taskAttachments:get': (attachmentURL) => `${BASE_URL}${attachmentURL}`,
    'taskComment:post': (taskId) => `${BASE_URL}/v1/me/task/${taskId}/comment`,
    'taskAttachment:post': (taskId) => `${BASE_URL}/v1/me/task/${taskId}/attachment`,
    'taskAction:post': (taskId) => `${BASE_URL}/v1/me/task/${taskId}`,
  }
};
