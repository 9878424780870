import React, {
  useEffect,
  useRef,
  useState
}
from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  Box,
  LinearProgress,
  CardActionArea,
  Grid,
  Breadcrumbs,
}
from '@material-ui/core';
import {
  AccessTime,
  Attachment,
  Comment,
  Person
}
from '@material-ui/icons';
import {
  useDispatch,
  useSelector
}
from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getProjectTasks } from '../config/projectsApi';
import { saveProjectTasks } from '../actions/projectsActions';
import { useAuth } from '../components/AuthProvider';
import { saveTaskId } from '../actions/tasksActions';

const useStyles = makeStyles((theme) => ({
  containerProjectTasks: {
    display: 'flex',
    padding: '2%',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
  },
  breadcrumb: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  breadcrumblink: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
    textDecoration: 'none',
  },
  box: {
    display: 'flex',
    flexDirection: 'row', // Align columns horizontally
    width: '100%',
    height: '80vh', // Full height of the screen for the container
    overflow: 'hidden', // Optional: Prevents scrolling outside the container
  },
  column: {
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    height: '100%', // Match the parent's height (screen height in this case)
    width: '30%',
    margin: '1%',
    overflowY: 'auto', // Enable vertical scrolling for content inside the column
  },
  columnHeader: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `2px solid ${theme.palette.divider}`,
    fontWeight: 700,
    fontSize: '18px',
    textAlign: 'center',
    color: '#fff',
    padding: theme.spacing(1),
    borderRadius: '4px',
    marginBottom: theme.spacing(2),
  },
  card: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: '#fff',
    width: '90%',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  },
  personRounded: {
    paddingTop: '5px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    fontWeight: 500,
  },
  cardContent: {
    fontSize: '14px',
    color: '#666',
  },
  iconGroup: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}));

function ProjectTasks({ searchQuery }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { authRefreshToken } = useAuth();

  const [groupedTasks, setGroupedTasks] = useState({});
  const [responseMessage, setResponseMessage] = useState('');

  const hasFetched = useRef(false);
  const saveToken = localStorage.getItem('token');
  const savedProjectId = localStorage.getItem('projectId');
  const savedTasksState = localStorage.getItem('selectedTasksState');

  const token = useSelector((state) => state.account.token);
  const tasksState = useSelector((state) => state.projects.tasksState);
  const projectId = useSelector((state) => state.projects.projectId);
  const projectTasks = useSelector((state) => state.projects.projectTasks);

  // Decode the URL
  const decodedPath = decodeURIComponent(location.pathname).split('/');
  const decodedPathName = decodedPath.length > 2 ? decodedPath[2] : null;

  const projectTasksFetch = async (name) => {
    const comingProjectTasksResponse = await getProjectTasks(!token ? saveToken : token,
      !projectId ? savedProjectId : projectId, name);

    if (comingProjectTasksResponse.status === 200) {
      // save project tasks to state
      dispatch(saveProjectTasks([...comingProjectTasksResponse.data.data]));

      // save response message
      setResponseMessage(comingProjectTasksResponse.data.message);
    } else if (comingProjectTasksResponse.status === 401) {
      authRefreshToken(true);
    }
  };

  const groupTasksByState = (tasks) => {
    const grouped = {};
    // Group tasks by their state
    tasks.forEach((task) => {
      if (!grouped[task.state]) {
        grouped[task.state] = [];
      }
      grouped[task.state].push(task);
    });

    // Define the desired order
    const stateOrder = ['Pending(Client)', 'Pending(dmc)', 'In Progress', 'Completed'];

    const orderedGrouped = {};
    // Create a new object to maintain the order
    stateOrder.forEach((state) => {
      if (grouped[state]) {
        orderedGrouped[state] = grouped[state];
      }
    });

    return orderedGrouped;
  };

  const handleTaskDetails = (event, task) => {
    // save task id to state
    dispatch(saveTaskId(task.id));
    // save task id to local storage
    localStorage.setItem('taskId', task.id);

    // Encode the path name to handle spaces and special characters
    const encodedPathName = decodeURIComponent(location.pathname);
    // Navigate to the new URL with state of decoded path name
    navigate(`/tasks/${task.name}/details`, {
      state: { from: encodedPathName }
    });
  };

  const getStateColor = (state) => {
    switch (state) {
      case 'Completed':
        return '#d6f5d6'; // Light green
      case 'Pending(dmc)':
        return '#d9eaf7'; // Light Blue
      case 'Pending(Client)':
        return '#f5d6d6'; // Light red
      case 'In Progress':
        return '#fff4d6'; // Light orange
      default:
        return '#E0E0E0'; // Light grey
    }
  };

  const getStateTextColor = (state) => {
    switch (state) {
      case 'Completed':
        return '#21BD1E'; // green
      case 'Pending(dmc)':
        return '#007BFF'; // blue
      case 'Pending(Client)':
        return '#FF0000'; // red
      case 'In Progress':
        return '#FF9500'; // orange
      default:
        return '#FFFFFF'; // black
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      projectTasksFetch();
    } else {
      setGroupedTasks(groupTasksByState(projectTasks));
    }
  }, [projectTasks]);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      // If there is a search query, fetch tasks with the query
      console.log('Fetching tasks with query:', searchQuery);
      projectTasksFetch(searchQuery);
    } else if ((searchQuery === '' && searchQuery.length === 0) || !hasFetched.current) {
      // If the search query is empty and we haven't fetched yet, fetch tasks without the query
      console.log('Fetching tasks without query');
      hasFetched.current = true; // Mark as fetched
      projectTasksFetch(); // Fetch without the query
    }
  }, [searchQuery]);

  return (
    <>
      {responseMessage !== 'Success' && projectTasks.length === 0 ? (
        <div className={classes.containerProjectTasks}>
          <Grid container>
            <Grid item xs={12} lg={12} md={12}>
              <Box className={classes.box}>
                <Typography className={classes.title}>
                  {responseMessage}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          {responseMessage === 'Success' && projectTasks.length === 0 ? (
            <LinearProgress />
          ) : (
            <div className={classes.containerProjectTasks}>
              <Grid container spacing={5}>
                <Grid item xs={12} lg={12} md={12}>
                  <Typography className={classes.title}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link onClick={() => navigate(-1)} className={classes.breadcrumblink}>
                        Projects
                      </Link>
                      <Typography className={classes.breadcrumb}>
                        {decodedPathName}
                      </Typography>
                      <Typography className={classes.breadcrumb}>
                        Tasks
                      </Typography>
                    </Breadcrumbs>
                  </Typography>
                </Grid>
                <Box className={classes.box}>
                  {(!tasksState ? savedTasksState === 'all' : tasksState === 'all') ? Object.keys(groupedTasks).map((state) => (
                    <div key={state} className={classes.column}>
                      <Typography
                        className={classes.columnHeader}
                        style={{
                          backgroundColor: getStateColor(state),
                          color: getStateTextColor(state)
                        }}
                      >
                        {state}
                      </Typography>
                      <div>
                        {groupedTasks[state] && groupedTasks[state].map((task) => (
                          <CardActionArea>
                            <Card
                              name={task.id}
                              onClick={(event) => handleTaskDetails(event, task)}
                              className={classes.card}
                              key={task.id}
                            >
                              <div className={classes.cardHeader}>
                                <Typography>{task.name}</Typography>
                                <div className={classes.iconGroup}>
                                  <AccessTime />
                                  <Typography>{task.deadline || '---'}</Typography>
                                </div>
                              </div>

                              <Typography className={classes.cardContent}>
                                {task.description || 'No description provided'}
                              </Typography>

                              <div>
                                <div className={classes.iconGroup}>
                                  <Typography>{task.attachments}</Typography>
                                  <Attachment />
                                  <Typography>{task.notes}</Typography>
                                  <Comment />
                                  <Typography>{task.assignees.length}</Typography>
                                  <Person />
                                </div>
                              </div>
                            </Card>
                          </CardActionArea>
                        ))}
                      </div>
                    </div>
                  )) : (
                    <div
                      key={!tasksState ? savedTasksState : tasksState}
                      className={classes.column}
                    >
                      <Typography
                        className={classes.columnHeader}
                        style={{
                          backgroundColor:
                          getStateColor(!tasksState ? savedTasksState : tasksState),
                          color: getStateTextColor(!tasksState ? savedTasksState : tasksState)
                        }}
                      >
                        {!tasksState ? savedTasksState : tasksState}
                      </Typography>
                      <div>
                        {groupedTasks[!tasksState ? savedTasksState : tasksState]
                    && groupedTasks[!tasksState ? savedTasksState : tasksState].map((task) => (
                      <CardActionArea>
                        <Card
                          name={task.id}
                          onClick={(event) => handleTaskDetails(event, task)}
                          className={classes.card}
                          key={task.id}
                        >
                          <div className={classes.cardHeader}>
                            <Typography>{task.name}</Typography>
                            <div className={classes.iconGroup}>
                              <AccessTime />
                              <Typography>{task.deadline || '---'}</Typography>
                            </div>
                          </div>
                          <Typography
                            className={classes.cardContent}
                          >
                            {task.description}
                          </Typography>
                          <div className={classes.iconGroup}>
                            {task.attachments}
                            <Attachment className={classes.attachment} />
                            {task.notes}
                            <Comment />
                          </div>
                          <div>
                            {task.assignees.length === 0 ? (
                              'not assigned yet'
                            ) : (
                              task.assignees.map((assignee) => (
                                <div key={assignee}>
                                  <Typography variant="body2" className={classes.projectContent}>
                                    <Person className={classes.personRounded} />
                                    {assignee}
                                  </Typography>
                                  {' '}
                                </div>
                              ))
                            )}
                          </div>
                        </Card>
                      </CardActionArea>
                    ))}
                      </div>
                    </div>
                  )}
                </Box>
              </Grid>
            </div>
          )}
        </>
      )}
    </>
  );
}

// Define prop types for Project Tasks component
ProjectTasks.propTypes = {
  searchQuery: PropTypes.string.isRequired, // Expecting a string for searchQuery
};

export default ProjectTasks;
