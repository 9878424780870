import {
  GET_PROJECT_ID, GET_PROJECT_TASKS, GET_PROJECT_TASKS_STATE, GET_PROJECTS
} from '../actions/projectsActions';

const initialState = {
  projects: [],
  tasks: [],
  projectTasks: [],
  projectId: 0,
  tasksState: '',
};

const ProjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload
      };
    case GET_PROJECT_TASKS:
      return {
        ...state,
        projectTasks: action.payload
      };
    case GET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload
      };
    case GET_PROJECT_TASKS_STATE:
      return {
        ...state,
        tasksState: action.payload
      };
    default:
      return state;
  }
};

export default ProjectsReducer;
