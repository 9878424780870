/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from '../config/accountApi';
import { saveDashboardData } from '../actions/accountActions';

const useStyles = makeStyles(() => ({
  container: {
    margin: '3vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '2vw',
  },
  topCharts: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5vw',
    maxHeight: '100vh',
    margin: '3vw',
  },
  scrollableSection: {
    margin: '3vw',
    maxHeight: '80vh',
    overflowY: 'auto',
    padding: '1vw',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  chartContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '1vw',
    maxHeight: '100vh',
  },
  chartContainerProject: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '1vw',
    maxWidth: '100%',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '5px',
    color: '#0E2040',
  },
  divider: {
    border: '0.5px dashed gray',
    width: '100%',
  },
}));

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasFetched = useRef(false);
  const [responseMessage, setResponseMessage] = useState('');
  const token = useSelector((state) => state.account.token);
  const dashboardData = useSelector((state) => state.account.dashboardData);

  const totalTasksState = dashboardData.total_tasks_per_state || {};
  const totalTasksPer = dashboardData.total_tasks || 0;
  const totalProjectsState = dashboardData.total_projects_per_state || {};
  const totalProjectsPer = dashboardData.total_projects || 0;
  const saveToken = localStorage.getItem('token');

  const dashboardFetch = async (page, name) => {
    const comingDashboardResponse = await getDashboard(!token ? saveToken : token, page, name);

    if (comingDashboardResponse.status === 200) {
      dispatch(saveDashboardData({ ...comingDashboardResponse.data.data }));
      setResponseMessage(comingDashboardResponse.data.message);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      dashboardFetch();
    }
  }, []);

  return (
    <Box className={classes.scrollableSection}>
      {responseMessage !== 'Success' ? (
        <Typography className={classes.title}>{responseMessage}</Typography>
      ) : (
        <>
          {responseMessage === 'Success' && dashboardData.length === 0 ? (
            <LinearProgress />
          ) : (
            <>
              <Box className={classes.topCharts}>
                <Box className={classes.chartContainer}>
                  <Typography className={classes.title}>Total Projects</Typography>
                  <hr className={classes.divider} />
                  <Grid className={classes.chartContainer}>
                    <Chart
                      options={{
                        plotOptions: {
                          pie: {
                            customScale: 1,
                            donut: {
                              size: '60%',
                              labels: {
                                show: true,
                                name: {
                                  show: true,
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  color: '#333',
                                  offsetY: -1,
                                },
                                value: {
                                  show: true,
                                  fontSize: '20px',
                                  fontWeight: 700,
                                  color: '#666',
                                  formatter: (val) => `${Math.round(val)}%`,
                                },
                                total: {
                                  showAlways: true,
                                  show: true,
                                  fontSize: '18px',
                                  fontWeight: 700,
                                  color: '#000',
                                  label: 'Total Projects',
                                  formatter: () => `${totalProjectsPer}`,
                                },
                              },
                            },
                          },
                        },
                        chart: {
                          type: 'donut',
                          background: '#FFFFFF',
                          foreColor: '#333',
                          height: '10px',
                        },
                        labels: ['New', 'In Progress', 'Completed', 'Cancelled'],
                        legend: {
                          position: 'bottom',
                          fontSize: '14px',
                          labels: {
                            colors: ['#333'],
                          },
                          markers: {
                            width: 12,
                            height: 12,
                          },
                        },
                        colors: ['#1E90FF', '#FFA500', '#28A745', '#DC3545'], // Custom colors for chart segments
                        dataLabels: {
                          enabled: true,
                          style: {
                            fontSize: '14px',
                            fontWeight: 'bold',
                            colors: ['#FFF'],
                          },
                          dropShadow: {
                            enabled: true,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#000',
                            opacity: 0.4,
                          },
                        },
                        tooltip: {
                          enabled: true,
                          theme: 'light',
                          y: {
                            formatter: (value) => `${value.toFixed(2)}%`,
                          },
                        },
                      }}
                      series={[
                        (totalProjectsState.new / totalProjectsPer) * 100,
                        (totalProjectsState.in_progress / totalProjectsPer) * 100,
                        (totalProjectsState.completed / totalProjectsPer) * 100,
                        (totalProjectsState.canceled / totalProjectsPer) * 100,
                      ]}
                      type="donut"
                    />
                  </Grid>
                </Box>

                <Box className={classes.chartContainer}>
                  <Typography className={classes.title}>Total Tasks</Typography>
                  <hr className={classes.divider} />
                  <Chart
                    options={{
                      plotOptions: {
                        pie: {
                          customScale: 1,
                          donut: {
                            size: '60%',
                            labels: {
                              show: true,
                              name: {
                                show: true,
                                fontSize: '16px',
                                fontWeight: 700,
                                color: '#333',
                                offsetY: -1,
                              },
                              value: {
                                show: true,
                                fontSize: '20px',
                                fontWeight: 700,
                                color: '#666',
                                formatter: (val) => `${Math.round(val)}%`,
                              },
                              total: {
                                showAlways: true,
                                show: true,
                                fontSize: '18px',
                                fontWeight: 700,
                                color: '#000',
                                label: 'Total Tasks',
                                formatter: () => `${totalTasksPer}`,
                              },
                            },
                          },
                        },
                      },
                      chart: {
                        type: 'donut',
                        background: '#FFFFFF',
                        foreColor: '#333',
                      },
                      labels: ['DMC Pending', 'Client Pending', 'In Progress', 'Completed'],
                      legend: {
                        position: 'bottom',
                        fontSize: '14px',
                        labels: {
                          colors: ['#333'],
                        },
                        markers: {
                          width: 12,
                          height: 12,
                        },
                      },
                      colors: ['#FF6347', '#FFD700', '#1E90FF', '#32CD32'],
                      dataLabels: {
                        enabled: true,
                        style: {
                          fontSize: '14px',
                          fontWeight: 'bold',
                          colors: ['#FFF'],
                        },
                        dropShadow: {
                          enabled: true,
                          top: 1,
                          left: 1,
                          blur: 1,
                          color: '#000',
                          opacity: 0.5,
                        },
                      },
                      tooltip: {
                        enabled: true,
                        theme: 'light',
                        y: {
                          formatter: (value) => `${value.toFixed(2)}%`,
                        },
                      },
                    }}
                    series={[
                      (totalTasksState.pending / totalTasksPer) * 100,
                      (totalTasksState.c_pending / totalTasksPer) * 100,
                      (totalTasksState.in_progress / totalTasksPer) * 100,
                      (totalTasksState.completed / totalTasksPer) * 100,
                    ]}
                    type="donut"
                  />
                </Box>
              </Box>

              <Grid container spacing={2}>
                {dashboardData.projects
                  ?.filter((project) => Object.keys(project.stage_percentage).length > 0)
                  .map((project, index) => (
                    <Grid item xs={4} lg={4} md={4}>
                      <Box key={index} className={classes.chartContainerProject}>
                        <Typography className={classes.title}>
                          {project.project_name}
                        </Typography>
                        <hr className={classes.divider} />
                        <Chart
                          options={{
                            plotOptions: {
                              pie: {
                                customScale: 1,
                                donut: {
                                  size: '60%',
                                  labels: {
                                    show: true,
                                    name: {
                                      show: true,
                                      fontSize: '16px',
                                      fontWeight: 700,
                                      color: '#333',
                                      offsetY: -1,
                                    },
                                    value: {
                                      show: true,
                                      fontSize: '20px',
                                      fontWeight: 700,
                                      color: '#666',
                                    },
                                  },
                                },
                              },
                            },
                            chart: {
                              type: 'donut',
                              background: '#FFFFFF',
                              foreColor: '#333',
                            },
                            labels: ['DMC Pending', 'Client Pending', 'In Progress', 'Completed'],
                            legend: {
                              position: 'bottom',
                              fontSize: '14px',
                              labels: {
                                colors: ['#333'],
                              },
                              markers: {
                                width: 12,
                                height: 12,
                              },
                            },
                            colors: ['#FF6347', '#FFD700', '#1E90FF', '#32CD32'],
                            dataLabels: {
                              enabled: true,
                              style: {
                                fontSize: '14px',
                                fontWeight: 'bold',
                                colors: ['#FFF'],
                              },
                              dropShadow: {
                                enabled: true,
                                top: 1,
                                left: 1,
                                blur: 1,
                                color: '#000',
                                opacity: 0.5,
                              },
                            },
                            tooltip: {
                              enabled: true,
                              theme: 'light',
                            },
                          }}
                          type="donut"
                          series={Object.values(project.stage_percentage)}
                        />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default Dashboard;
