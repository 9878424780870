import axios from 'axios';
import { API_ROUTES } from '.';

export const getProjects = async (token, page, name = '') => {
  let response;
  try {
    response = await axios({
      url: API_ROUTES.projects['projects:get'],
      method: 'GET',
      headers: { Authorization: `Bearer ${token}`, 'Content-Language': 'en_US' },
      params: {
        page,
        limit: 6,
        name,
      }
    });
  } catch (error) {
    console.log('get ptojects error');
    response = error;
  }

  return response;
};

export const getProjectTasks = async (token, projectId, name = '') => {
  let response;
  try {
    response = await axios({
      url: API_ROUTES.projects['projectTasks:get'](projectId),
      method: 'GET',
      headers: { Authorization: `Bearer ${token}`, 'Content-Language': 'en_US' },
      params: {
        name,
      }
    });
  } catch (error) {
    console.log('get project tasks error');
    response = error;
  }

  return response;
};

export default {};
