/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dashboard, FolderOpen, Image, Settings
} from '@material-ui/icons';
import { Link, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import { useAuth } from './AuthProvider';

const useStyles = makeStyles((theme) => ({
  nav: {
    marginTop: '2vh',
    backgroundColor: theme.palette.background.paper,
    paddingTop: '5vh',
    height: '83vh',
  },
  sideNav: {
    height: '100%',
    width: '100%',
    paddingTop: '0.5vh',
  },
  sideNavItem: {
    marginBottom: '0.5em',
  },
  linkColor: {
    color: 'black',
    textDecoration: 'none',
  },
  selected: {
    backgroundColor: '#83C29D33',
  },
}));

function MainComponent({ searchQuery, setSearchQuery }) {
  const classes = useStyles();
  const { authRefreshToken } = useAuth();

  // add useRef to avoid 2 calls for the api
  const hasFetched = useRef(false);

  // retrieve selectedIndex in side nav  from local storage
  const newSelectedIndex = localStorage.getItem('selectedIndex');

  const handleListItemClick = (event, newValue) => {
    // save selectedIndex in side nav to local storage
    localStorage.setItem('selectedIndex', newValue);

    // clear search status while navigation on sidenav
    setSearchQuery('');
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      authRefreshToken();
    }
  }, []);

  return (
    <div>
      <Grid container spacing={12}>
        <Grid item xs={12}>
          <Navbar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Grid>

        {/* TODO: modify side nav in the mobile vision */}
        <Grid item xs={12} lg={2} md={2} className={classes.sideNav}>
          <Card>
            <List className={classes.nav}>
              <Link className={classes.linkColor} to="/">
                <ListItem
                  className={`${classes.sideNavItem} ${Number(newSelectedIndex) === 0 ? classes.selected : ''}`}
                  button
                  selected={Number(newSelectedIndex) === 0}
                  onClick={(event) => handleListItemClick(event, 0)}
                >
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </Link>
              <Link className={classes.linkColor} to="/projects">
                <ListItem
                  className={`${classes.sideNavItem} ${Number(newSelectedIndex) === 1 ? classes.selected : ''}`}
                  button
                  selected={Number(newSelectedIndex) === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    <FolderOpen />
                  </ListItemIcon>
                  <ListItemText primary="Projects" />
                </ListItem>
              </Link>
              <Link className={classes.linkColor} to="/tasks">
                <ListItem
                  button
                  className={`${classes.sideNavItem} ${Number(newSelectedIndex) === 2 ? classes.selected : ''}`}
                  selected={Number(newSelectedIndex) === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                >
                  <ListItemIcon>
                    <Image />
                  </ListItemIcon>
                  <ListItemText primary="Tasks" />
                </ListItem>
              </Link>
              <Link className={classes.linkColor} to="/profile">
                <ListItem
                  button
                  className={`${classes.sideNavItem} ${Number(newSelectedIndex) === 3 ? classes.selected : ''}`}
                  selected={Number(newSelectedIndex) === 3}
                  onClick={(event) => handleListItemClick(event, 3)}
                >
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>
              </Link>
            </List>
          </Card>
        </Grid>

        <Grid item xs={12} lg={10} md={10}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
}

// Define prop types for Main component
MainComponent.propTypes = {
  searchQuery: PropTypes.string.isRequired, // Expecting a string for searchQuery
  setSearchQuery: PropTypes.func.isRequired, // Expecting a function for setSearchQuery
};

export default MainComponent;
