import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import {
  saveUserForm
} from '../actions/accountActions';
import { getAccountDetails } from '../config/accountApi';
import login from '../images/login.png';
import logo from '../images/logo.png';
import { useAuth } from '../components/AuthProvider';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: 'black',
    color: 'white'
  },
  loginTitle: {
    fontFamily: '"Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: '800',
    lineHeight: '49.18px',
    fontSize: '36px',
    marginBottom: '3.5vh',
    color: 'black',
  },
  boxMargin: {
    marginBottom: '3.5vh',
  },
  appLogin: {
    width: '90%',
  },
  appLogo: {
    height: '6vh',
    paddingTop: '1vh'
  },
}));

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authLogin } = useAuth();

  const token = useSelector((state) => state.account.token);

  const [loginForm, setLoginForm] = useState({
    username: '',
    password: ''
  });
  const [doneSubmit, setDoneSubmit] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    // save username and password to state to be send in the login request
    dispatch(saveUserForm({ ...loginForm }));

    try {
      const successLoginResponse = await getAccountDetails(loginForm);
      authLogin(successLoginResponse);

      navigate('/');
    } catch (error) {
      setDoneSubmit(true);
      setLoader(false);
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setDoneSubmit(false);
    setLoginForm((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleClickShowPassword = () => {
    setRevealPassword((prev) => !prev);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={10} alignItems="center">
        <Grid item xs={12}><img src={logo} alt="logo" className={classes.appLogo} /></Grid>
        <Grid item xs={12} lg={7} md={7}><img src={login} className={classes.appLogin} alt="login" /></Grid>
        <Grid item xs={12} lg={5} md={5}>
          <form onSubmit={handleSubmit}>
            <Box className={classes.boxMargin} mt={2}>
              <Typography variant="body1" className={classes.loginTitle}> Welcome Back! </Typography>
            </Box>
            <Box className={classes.boxMargin} mt={2}>
              <TextField
                disabled={!!loader}
                name="username"
                fullWidth
                required
                type="text"
                onChange={(event) => handleChange(event)}
                label="Email"
                placeholder=""
                margin="normal"
                variant="outlined"
              />
            </Box>
            <Box className={classes.boxMargin} mt={2}>
              <TextField
                disabled={!!loader}
                name="password"
                required
                fullWidth
                label="Password"
                type={revealPassword ? 'text' : 'password'}
                onChange={(event) => handleChange(event)}
                placeholder={revealPassword ? loginForm.password : '********'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {revealPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                margin="normal"
                variant="outlined"
              />
            </Box>
            <Box className={classes.boxMargin} mt={2}>
              {/* TODO: will be modified to check on the response not the token */}
              {token === '' && doneSubmit ? (
                <Alert severity="error">Invalid username or password. Please check your details and try again!</Alert>
              ) : (
                <></>
              )}
            </Box>
            <Box className={classes.boxMargin} mt={2}>
              <Button disabled={!!loader} className={classes.button} fullWidth variant="contained" color="black" type="submit">
                {loader ? <CircularProgress /> : 'Log In'}
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;
