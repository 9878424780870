export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SAVE_USER_DETAILS = 'SAVE_USER_DETAILS';
export const SAVE_TOKEN_EXPIRY_TIME = 'SAVE_TOKEN_EXPIRY_TIME';
export const SAVE_REFRESH_TOKEN = 'SAVE_REFRESH_TOKEN';
export const SAVE_REFRESH_TOKEN_EXPIRY_TIME = 'SAVE_REFRESH_TOKEN_EXPIRY_TIME';
export const SAVE_DASHBOARD_DATA = 'SAVE_DASHBOARD_DATA';
export const LOGOUT = 'LOGOUT';

export const saveToken = (token) => ({
  type: LOGIN_SUCCESS,
  payload: token,
});

export const Logout = (logout) => ({
  type: LOGOUT,
  payload: logout,
});

export const saveUserForm = (user) => ({
  type: LOGIN_REQUEST,
  payload: user,
});

export const saveUserDetails = (userDetails) => ({
  type: SAVE_USER_DETAILS,
  payload: userDetails,
});

export const saveTokenExpiryTime = (tokenExpiryTime) => ({
  type: SAVE_TOKEN_EXPIRY_TIME,
  payload: tokenExpiryTime,
});

export const saveRefreshToken = (refreshToken) => ({
  type: SAVE_REFRESH_TOKEN,
  payload: refreshToken,
});

export const saveRefreshTokenExpiryTime = (refreshTokenExpiryTime) => ({
  type: SAVE_REFRESH_TOKEN_EXPIRY_TIME,
  payload: refreshTokenExpiryTime,
});

export const saveDashboardData = (dashboardData) => ({
  type: SAVE_DASHBOARD_DATA,
  payload: dashboardData,
});
