/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  AssignmentInd, Brightness4, Brightness6, Email, Language, Person, Phone, Public
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid #ddd',
    margin: '3vw',
    padding: '1vw',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    maxHeight: '80vh',
    overflowY: 'auto',
    borderRadius: '8px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#f7f7f7',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[2],
  },
  picture: {
    width: 100,
    height: 100,
    borderRadius: '50%',
    objectFit: 'cover',
    marginBottom: theme.spacing(2),
  },
  info: {
    textAlign: 'center',
  },
  name: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  bio: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stats: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  stat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  statValue: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
  },
  statLabel: {
    fontSize: '0.875rem',
  },
}));

function Profile() {
  const classes = useStyles();

  // retrieve user image and name from local storage
  const savedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
  const savedUserImageUrl = localStorage.getItem('userImageUrl');

  const handleThemeChange = () => {
    // localStorage.setItem('darkMode', !darkMode);
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <CssBaseline />
      {/* <Card className={classes.card}>
        <Avatar className={classes.picture} src={savedUserImageUrl} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                <Person fontSize="small" style={{ marginRight: 8 }} />
                Name:
                {' '}
                {savedUserDetails.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                <Email fontSize="small" style={{ marginRight: 8 }} />
                Email:
                {savedUserDetails.email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                <Phone fontSize="small" style={{ marginRight: 8 }} />
                Mobile:
                {savedUserDetails.mobile}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                <AssignmentInd fontSize="small" style={{ marginRight: 8 }} />
                Role:
                {savedUserDetails.role === 'write' ? 'Admin' : 'User'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                <Public fontSize="small" style={{ marginRight: 8 }} />
                Time Zone:
                {savedUserDetails.timezone}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                <Language fontSize="small" style={{ marginRight: 8 }} />
                Language:
                {savedUserDetails.language}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}

      <Card className={classes.card}>
        <CardMedia
          className={classes.picture}
          image={savedUserImageUrl}
          title="Profile Picture"
        />
        <CardContent className={classes.info}>
          <Typography className={classes.name} variant="h5" component="h2">
            {savedUserDetails.name}
          </Typography>
          <Typography className={classes.bio} variant="body1">
            <AssignmentInd fontSize="small" style={{ marginRight: 8 }} />
            {savedUserDetails.role === 'write' ? 'Admin' : 'User'}
          </Typography>
          <Grid container className={classes.stats}>
            <Grid item xs={12} sm={12} md={12} className={classes.stat}>
              <Typography className={classes.statValue} variant="body1">
                <Email fontSize="small" style={{ marginRight: 8 }} />
                {savedUserDetails.email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.stat}>
              <Typography className={classes.statValue} variant="body1">
                <Phone fontSize="small" style={{ marginRight: 8 }} />
                {savedUserDetails.mobile === 'False' ? 'Not Available' : savedUserDetails.mobile}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.stat}>
              <Typography className={classes.statValue} variant="body1">
                <Public fontSize="small" style={{ marginRight: 8 }} />
                {savedUserDetails.timezone}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={classes.stat}>
              <Typography className={classes.statValue} variant="body1">
                <Language fontSize="small" style={{ marginRight: 8 }} />
                {savedUserDetails.language}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Profile;
