import axios from 'axios';
import { API_ROUTES } from '.';

export const getAccountDetails = async (loginForm) => {
  const basicAuth = `Basic ${btoa(`${loginForm.username}:${loginForm.password}`)}`;
  let response;

  try {
    response = await axios({
      url: API_ROUTES.account['token:get'],
      method: 'POST',
      headers: {
        authorization: basicAuth,
      },
    });
  } catch (error) {
    console.log('get access token error');
    response = error;
  }

  return response;
};

export const refreshTokenAccess = async (refreshToken) => {
  let response;
  try {
    response = await axios({
      url: API_ROUTES.account['refreshToken:get'],
      method: 'POST',
      data: {
        refresh_token: refreshToken,
      },
    });
  } catch (error) {
    console.log('refresh Token Access error');
    response = error;
  }

  return response;
};

export const revokeToken = async (token) => {
  const bearerAuth = `Bearer ${token}`;
  let response;
  try {
    response = await axios({
      url: API_ROUTES.account['revokeToken:post'],
      method: 'POST',
      headers: {
        authorization: bearerAuth,
      },
    });
  } catch (error) {
    console.log('revoke Token error');
    response = error;
  }

  return response;
};

export const getDashboard = async (token) => {
  const bearerAuth = `Bearer ${token}`;
  let response;
  try {
    response = await axios({
      url: API_ROUTES.account['dashboard:get'],
      method: 'GET',
      headers: {
        Authorization: bearerAuth,
        'Content-Language': 'en_US'
      },
    });
  } catch (error) {
    console.log('get dashboard error');
    response = error;
  }

  return response;
};

export default {};
