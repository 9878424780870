/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  LinearProgress,
  TextField
} from '@material-ui/core';
import { Attachment, Comment } from '@material-ui/icons';

import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import { useAuth } from '../components/AuthProvider';
import { saveTaskId, saveTasks } from '../actions/tasksActions';
import { getTasks } from '../config/tasksApi';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    marginRight: '0.3vw',
  },
  pagination: {
    '& > *': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
  },
  item: {
    width: '482px',
    height: '100%',
  },
  title: {
    marginTop: '1vw',
    marginLeft: '1em',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
  },
  box: {
    backgroundColor: 'white',
    borderRadius: '0.5em',
    marginLeft: '0.3em',
    flex: '1 1 300px',
    maxWidth: '100%',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    display: 'flex',
    height: '70vh',
    flexDirection: 'column'
  },
  tableBody: {
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  taskHeaderName: {
    width: '50%',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '18px',
  },
  taskHeader: {
    backgroundColor: '#d6f5d6',
    borderRadius: '15px',
  },
  status: {
    padding: '8px 16px',
    borderRadius: '5px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'inline-block',
  },
  new: {
    backgroundColor: '#d9eaf7', // Light Blue
    color: '#007BFF', // Blue text
  },
  search: {
    backgroundColor: 'white',
    marginTop: '5px',
  },
  completed: {
    backgroundColor: '#d6f5d6', // Light Green
    color: '#21BD1E', // Green text
  },
  inProgress: {
    backgroundColor: '#fff4d6', // Light Orange
    color: '#FF9500', // Orange text
  },
  cPending: {
    backgroundColor: '#f5d6d6', // Light Red
    color: '#FF0000', // Red text
  },
  dPending: {
    backgroundColor: '#d9eaf7', // Light Blue
    color: '#007BFF', // Blue text
  },
  headerContent: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '18px',
  },
  taskContent: {
    fontFamily: 'Inter',
    fontWeight: '300',
    fontSize: '16px',
  },
  attachment: {
    paddingTop: '5px',
    marginBottom: '-6px',
  },
  containerTasks: {
    margin: '1vw',
    paddingRight: '2vw',
    height: '90%',
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
}));

function Tasks({ searchQuery }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { authRefreshToken } = useAuth();

  // useRef to prevent multiple API calls
  const hasFetched = useRef(false);
  const token = useSelector((state) => state.account.token);
  // Retrieve token from local storage
  const saveToken = localStorage.getItem('token');
  const stateTasks = useSelector((state) => state.tasks.tasks);

  const [tasksMetaData, setTasksMetaData] = useState({});
  const [paginationPage, setPaginationPage] = useState(1);
  const [tasksSearchQuery, setTasksSearchQuery] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const tasksFetch = async (page, project, name) => {
    const comingTasksResponse = await getTasks(token || saveToken, page, project, name);

    if (comingTasksResponse.status === 200) {
      // Save tasks to state
      dispatch(saveTasks([...comingTasksResponse.data.data]));

      // Save tasks metadata to state
      setTasksMetaData({ ...comingTasksResponse.data.metadata });

      // save response message
      setResponseMessage(comingTasksResponse.data.message);
    } else if (comingTasksResponse.status === 401) {
      authRefreshToken(true);
    }
  };

  const handleChange = (event, newValue) => {
    setPaginationPage(newValue);
    tasksFetch(newValue);
  };

  const handleSearchChange = (event) => {
    setTasksSearchQuery(event.target.value);
    tasksFetch(paginationPage, tasksSearchQuery);
  };

  const handleTaskDetails = (event, task) => {
    // save task id to state
    dispatch(saveTaskId(task.id));
    // save task id to local storage
    localStorage.setItem('taskId', task.id);

    // Encode the path name to handle spaces and special characters
    const encodedPathName = decodeURIComponent(location.pathname);
    // Navigate to the new URL with state of decoded path name
    navigate(`/tasks/${task.name}/details`, {
      state: { from: encodedPathName }
    });
  };

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      // If there is a search query, fetch tasks with the query
      console.log('Fetching tasks with query:', searchQuery);
      tasksFetch(paginationPage, '', searchQuery);
    } else if (tasksSearchQuery === '' && tasksSearchQuery.length === 0 && ((searchQuery === '' && searchQuery.length === 0) || !hasFetched.current)) {
      // If the search query is empty and we haven't fetched yet, fetch tasks without the query
      console.log('Fetching tasks without query');
      hasFetched.current = true; // Mark as fetched
      tasksFetch(paginationPage); // Fetch without the query
    }
  }, [searchQuery, paginationPage, tasksSearchQuery]);

  return (
    <>
      {searchQuery && responseMessage !== 'Success' && stateTasks.length === 0 ? (
        <div className={classes.containerProjectTasks}>
          <Grid container>
            <Grid item xs={12} lg={12} md={12}>
              <Box>
                <Typography className={classes.title}>
                  {responseMessage}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          {responseMessage === 'Success' && stateTasks.length === 0 ? (
            <LinearProgress />
          ) : (
            <>
              <div className={classes.containerTasks}>
                <Grid container spacing={4}>
                  <Grid item xs={8} lg={8} md={8}>
                    <Typography className={classes.title}>All Tasks</Typography>
                  </Grid>

                  <Grid item xs={4} lg={4} md={4}>
                    <TextField id="search" className={classes.search} value={tasksSearchQuery} fullWidth placeholder="Search Project..." variant="outlined" onChange={handleSearchChange} />
                  </Grid>

                  <Grid item xs={12} lg={12} md={12}>
                    <TableContainer component={Box} className={classes.box}>
                      <Table>
                        <TableHead className={classes.stickyHeader}>
                          <TableRow className={classes.taskHeader}>
                            <TableCell colSpan={2} className={classes.taskHeaderName}>
                              Task Details
                            </TableCell>
                            <TableCell className={classes.headerContent}>Start-Time</TableCell>
                            <TableCell className={classes.headerContent}>Deadline</TableCell>
                            <TableCell className={classes.headerContent}>State</TableCell>
                            <TableCell className={classes.headerContent}>Attachments</TableCell>
                            <TableCell className={classes.headerContent}>Comments</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                          {stateTasks.length !== 0 ? (stateTasks.map((task, index) => (
                            <TableRow
                              name={task.id}
                              hover
                              onClick={(event) => handleTaskDetails(event, task)}
                              key={task.id || index}
                            >
                              <TableCell colSpan={2} className={classes.taskContent}>
                                <Typography variant="h6" component="h1">
                                  {task.name}
                                  . (
                                  {task.project_name}
                                  )
                                </Typography>
                                <Typography variant="body1" className={classes.taskContent} component="h2">
                                  {task.description}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.taskContent}>{task.start_time ? task.start_time : '---'}</TableCell>
                              <TableCell>
                                <Typography variant="body2" color="error" className={classes.taskContent}>
                                  {task.deadline ? task.deadline : '---'}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={`${classes.status} ${task.state === 'New' ? classes.new : task.state === 'Completed' ? classes.completed : task.state === 'In Progress' ? classes.inProgress : task.state === 'Pending' ? classes.pending : task.state === 'Pending(Client)' ? classes.cPending : classes.dPending}`}>
                                  {task.state}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.taskContent}>
                                <Typography variant="body2" className={classes.taskContent}>
                                  {task.attachments}
                                  <Attachment className={classes.attachment} />
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.taskContent}>
                                <Typography variant="body2" className={classes.taskContent}>
                                  {task.notes}
                                  <Comment className={classes.attachment} />
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))) : (
                            <TableRow>
                              <TableCell colSpan={2} className={classes.taskContent}>
                                <Typography variant="h6" component="h1">
                                  No Matching Results Found
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                </Grid>
              </div>
              <Grid item xs={12} className={classes.pagination}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  color="#007BFF"
                  size="large"
                  count={tasksMetaData.pages}
                  page={paginationPage}
                  onChange={handleChange}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}

// Define prop types for Tasks component
Tasks.propTypes = {
  searchQuery: PropTypes.string.isRequired, // Expecting a string for searchQuery
};

export default Tasks;
