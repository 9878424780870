/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-constant-condition */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  LinearProgress,
  Breadcrumbs,
  Typography,
  Box,
  Card,
  Button,
  TextField,
  ListItem,
  Avatar,
  ListItemText,
  ListItemAvatar,
  List,
  CircularProgress,
} from '@material-ui/core';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Add,
  Assignment,
  Attachment, CheckCircle, DateRange, Person
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTaskAttachment, getTaskDetails, postTaskAction, postTaskAttachment, postTaskComment
} from '../config/tasksApi';
import { saveTaskDetails } from '../actions/tasksActions';

const useStyles = makeStyles((theme) => ({
  item: {
    width: '482px',
    height: '100%',
  },
  title: {
    marginTop: '1vw',
    marginLeft: '1em',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
  },
  breadcrumb: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
  },
  box: {
    padding: '1.5vh',
    backgroundColor: 'white',
    borderRadius: '0.5em',
    marginLeft: '1em',
  },
  taskName: {
    fontFamily: 'Inter',
    fontSize: '2em',
    fontWeight: 'bold',
    lineHeight: '30.26px',
    color: '#000000',
    whiteSpace: 'nowrap',
    width: '85%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  taskHeader: {
    backgroundColor: '#d6f5d6', // Light Green
    borderRadius: '15px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  },
  card: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: '8px',
    width: '100%',
    overflowY: 'auto',
  },
  board: {
    backgroundColor: 'white',
    borderRadius: '0.5em',
    marginLeft: '2em',
    flex: '1 1 300px',
    maxWidth: '100%',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    display: 'flex',
    height: '80vh',
  },
  divider: {
    border: '0.1px solid #80808030',
    width: '100%',
    marginTop: '3%',
    marginBottom: '3%',
  },
  status: {
    padding: '8px 16px',
    borderRadius: '15px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'inline-block',
  },
  iconGroup: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  breadcrumblink: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
    textDecoration: 'none',
  },
  new: {
    backgroundColor: '#d9eaf7', // Light Blue
    color: '#007BFF', // Blue text
  },
  completed: {
    backgroundColor: '#d6f5d6', // Light Green
    color: '#21BD1E', // Green text
  },
  inProgress: {
    backgroundColor: '#fff4d6', // Light Orange
    color: '#FF9500', // Orange text
  },
  pending: {
    backgroundColor: '#f5d6d6', // Light Red
    color: '#FF0000', // Red text
  },
  headerContent: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
  },
  icons: {
    paddingTop: '5px',
  },
  taskContent: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '16px',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    fontWeight: 500,
  },
  approveButton: {
    marginTop: '0.4em',
    marginRight: '0.4em',
    backgroundColor: '#21BD1E',
    color: 'white',
    height: '5.7vh'
  },
  rejectButton: {
    marginTop: '0.4em',
    backgroundColor: 'red',
    color: 'white',
    height: '5.7vh'
  },
  buttonComment: {
    backgroundColor: '#21BD1E',
    color: 'white',
    height: '5.3vh',
    marginTop: '1.5vh',
    margin: '1vh',
    width: '10%',
  },
  cardContentTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '30%',
  },
  cardContentValue: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '30%',
  },
  main: {
    margin: '1vw',
    paddingRight: '2vw',
    height: '90%',
  },
  attachment: {
    paddingTop: '5px',
    marginBottom: '-6px',
  },
  sectionTitle: {
    fontFamily: 'Inter',
    fontSize: '1.5em',
    fontWeight: 600,
    color: '#0E2040',
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  },
}));

function TaskDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const hasFetched = useRef(false);
  const [taskComment, setTaskComment] = useState('');
  const [commentLoader, setCommentLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const decodedPath = decodeURIComponent(location?.state?.from)?.split('/');

  const savedToken = localStorage.getItem('token');
  const savedTaskId = localStorage.getItem('taskId');
  const savedUserImageUrl = localStorage.getItem('userImageUrl');
  const savedUserRole = localStorage.getItem('role');

  const token = useSelector((state) => state.account.token);
  const taskDetails = useSelector((state) => state.tasks.taskDetails);
  const taskId = useSelector((state) => state.tasks.taskId);

  const taskDetailsFetch = async () => {
    const comingTaskDetailsResponse = await getTaskDetails(!token ? savedToken : token,
      !taskId ? savedTaskId : taskId);

    if (comingTaskDetailsResponse.status === 200) {
      // save task details to state
      dispatch(saveTaskDetails({ ...comingTaskDetailsResponse.data.data[0] }));

      // save response message
      setResponseMessage(comingTaskDetailsResponse.message);
    }
  };

  const downloadTaskAttachment = async (event, attachment) => {
    try {
      const response = await getTaskAttachment(!token ? savedToken : token, attachment.url);

      const filteredFileType = attachment.name.split('.');
      const fileType = filteredFileType[filteredFileType.length - 1];

      // Create a new Blob object using the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${attachment.name}.${fileType}`); // Specify the file name

      // Append to the body, trigger click, and remove the link
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const handleCommentTextChange = (event) => {
    setTaskComment(event.target.value);
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleAddAttachment = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);

    try {
      const response = await postTaskAttachment(
        !token ? savedToken : token,
        !taskId ? savedTaskId : taskId,
        formData,
        selectedFile.name
      );

      if (response.status === 200) {
        console.log('Attachment uploaded successfully');
        taskDetailsFetch();
      } else {
        console.error('Failed to upload attachment. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading the attachment:', error);
    }
  };

  const handleAddComment = async (event) => {
    event.preventDefault();
    setCommentLoader(true);

    try {
      await postTaskComment(!token ? savedToken : token,
        !taskId ? savedTaskId : taskId, taskComment);
      taskDetailsFetch();
      setCommentLoader(false);
    } catch (error) {
      setCommentLoader(false);
    }
  };

  const handleAddAction = async (event, actionType) => {
    event.preventDefault();

    try {
      await postTaskAction(!token ? savedToken : token,
        !taskId ? savedTaskId : taskId, actionType);
      taskDetailsFetch();
    } catch (error) {
      console.error('Error approving or rejecting the task', error);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      taskDetailsFetch();
    }
  }, [taskDetails]);

  const getStateColor = (state) => {
    switch (state) {
      case 'Completed':
        return '#d6f5d6'; // Light green
      case 'Pending(Client)':
        return '#f5d6d6'; // Light red
      case 'Pending(dmc)':
        return '#d9eaf7'; // Light blue
      case 'In Progress':
        return '#fff4d6'; // Light orange
      default:
        return '#E0E0E0'; // Light grey
    }
  };

  const getStateTextColor = (state) => {
    switch (state) {
      case 'Completed':
        return '#21BD1E'; // green
      case 'Pending(Client)':
        return '#FF0000'; // red
      case 'Pending(dmc)':
        return '#007BFF'; // blue
      case 'In Progress':
        return '#FF9500'; // orange
      default:
        return '#FFFFFF'; // black
    }
  };

  return (
    <>
      {responseMessage !== 'Success' && Object.keys(taskDetails).length === 0 ? (
        <LinearProgress />
      ) : (
        <>
          <div className={classes.main}>
            <Grid container spacing={5}>
              <Grid item xs={12} lg={12} md={12}>
                <Typography className={classes.title}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link onClick={() => navigate(-1)} className={classes.breadcrumblink}>
                      {decodedPath.length > 2 ? decodedPath[2] : decodedPath[1]}
                    </Link>
                    <Typography className={classes.breadcrumb}>
                      {taskDetails.name}
                    </Typography>
                  </Breadcrumbs>
                </Typography>
              </Grid>

              <Box className={classes.board}>
                <Card className={classes.card}>
                  <div className={classes.cardHeader}>
                    <Typography className={classes.taskName}>{taskDetails.name}</Typography>
                    {taskDetails.need_approve ? (
                      <>
                        <Button
                          disabled={taskDetails.state === 'Completed' || savedUserRole === 'read'}
                          style={{ width: '10%' }}
                          onClick={(event) => handleAddAction(event, 'approve')}
                          className={classes.approveButton}
                          variant="contained"
                          type="submit"
                        >
                          Approve
                        </Button>
                        <Button
                          disabled={taskDetails.state === 'Completed' || savedUserRole === 'read'}
                          style={{ width: '10%' }}
                          onClick={(event) => handleAddAction(event, 'reject')}
                          className={classes.rejectButton}
                          variant="contained"
                          type="submit"
                        >
                          Reject
                        </Button>
                      </>
                    ) : (<></>)}
                  </div>
                  <br />
                  <div className={classes.iconGroup}>
                    <Assignment />
                    <Typography className={classes.cardContentTitle}>
                      Project
                    </Typography>
                    <Typography className={classes.cardContentValue}>
                      {taskDetails.project_name}
                    </Typography>
                  </div>
                  <br />
                  <div className={classes.iconGroup}>
                    <CheckCircle />
                    <Typography className={classes.cardContentTitle}>
                      Status
                    </Typography>
                    <Typography
                      style={{
                        backgroundColor: getStateColor(taskDetails.state),
                        color: getStateTextColor(taskDetails.state)
                      }}
                      className={classes.status}
                    >
                      {taskDetails.state}
                    </Typography>
                  </div>
                  <br />
                  <div className={classes.iconGroup}>
                    <Person />
                    <Typography className={classes.cardContentTitle}>
                      Assignees
                    </Typography>
                    <Typography className={classes.cardContentValue}>
                      {taskDetails
                        && taskDetails.assignees
                        && taskDetails.assignees.length === 0 ? (
                          'not assigned yet'
                        ) : (
                          taskDetails
                            && taskDetails.assignees
                            && taskDetails.assignees.map((assignee) => (
                              <div key={assignee} className={classes.iconGroup}>
                                <Typography>
                                  {assignee}
                                </Typography>
                              </div>
                            ))
                        )}
                    </Typography>
                  </div>
                  <br />
                  <div className={classes.iconGroup}>
                    <DateRange />
                    <Typography className={classes.cardContentTitle}>
                      Due Date
                    </Typography>
                    <Typography className={classes.cardContentValue}>{taskDetails.deadline || 'No deadline provided'}</Typography>
                  </div>
                  <hr className={classes.divider} />
                  <div>
                    <Typography className={classes.sectionTitle} variant="h6">Description</Typography>
                    <Typography variant="body1" className={classes.taskContent}>
                      <>
                        <Typography
                          variant="body1"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {taskDetails.description || 'No description provided' }
                        </Typography>
                      </>
                    </Typography>
                  </div>
                  <hr className={classes.divider} />
                  <div>
                    <Typography className={classes.sectionTitle} variant="h6">Attachments</Typography>
                    {taskDetails.attachments
                            && taskDetails.attachments.length === 0 ? (
                              <>
                                <Typography
                                  variant="body1"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  No attachments available
                                </Typography>
                              </>
                      ) : (taskDetails.attachments
                            && taskDetails.attachments.map((attachment, index) => (
                              attachment && (
                              <Button
                                onClick={(event) => downloadTaskAttachment(event, attachment)}
                                key={index}
                                className={classes.iconGroup}
                              >
                                <>
                                  <Attachment />
                                  <Typography>
                                    {attachment.name}
                                  </Typography>
                                </>
                              </Button>
                              )
                            ))
                      )}
                    <br />
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<Add />}
                      disabled={savedUserRole === 'read'}
                      style={{ justifyContent: 'flex-start' }}
                    >
                      {selectedFile ? selectedFile.name : 'Add Attachment'}
                      <input
                        id="file"
                        type="file"
                        hidden
                        name="file"
                        onChange={onFileChange}
                      />
                    </Button>
                    <Button onClick={handleAddAttachment} variant="contained" type="submit" disabled={!selectedFile}>Upload!</Button>
                  </div>
                  <hr className={classes.divider} />
                  <div>
                    <Typography className={classes.sectionTitle} variant="h6">Comments</Typography>
                    <List>
                      <div className={classes.iconGroup}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar alt="avatar" src={savedUserImageUrl} />
                          </ListItemAvatar>
                          <ListItemText
                            secondary={(
                              <>
                                <TextField
                                  disabled={commentLoader || savedUserRole === 'read'}
                                  name="comment"
                                  fullWidth
                                  multiline
                                  type="text"
                                  onChange={handleCommentTextChange}
                                  placeholder="Write Comment"
                                  margin="normal"
                                  variant="outlined"
                                />
                              </>
                            )}
                          />
                          <Button disabled={savedUserRole === 'read'} onClick={handleAddComment} className={classes.buttonComment} variant="contained" type="submit">
                            {commentLoader ? <CircularProgress /> : 'Add'}
                          </Button>
                        </ListItem>
                      </div>
                      {taskDetails
                            && taskDetails.notes
                            && taskDetails.notes.length === 0 ? (
                              <>
                                <br />
                                <Typography
                                  variant="body1"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  No comments yet. Be the first to comment!
                                </Typography>
                              </>
                        ) : (
                          taskDetails
                            && taskDetails.notes
                            && taskDetails.notes
                              .slice() // Create a shallow copy to avoid mutating the original array
                              .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
                              // Sort in descending order
                              .map((note, index) => (
                                <div key={index}>
                                  <div className={classes.iconGroup}>
                                    <Avatar alt="avatar" src={note.avatar} />
                                    <Typography variant="body2" className={classes.projectContent}>
                                      {note.user}
                                    </Typography>
                                    <Typography variant="caption">
                                      {note.datetime}
                                    </Typography>
                                  </div>
                                  <ListItem>
                                    <ListItemText
                                      primary={note.body}
                                      secondary={(
                                        <>

                                          <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                          >
                                            {note
                            && note.attachments
                            && note.attachments.length === 0 ? (
                                                ' '
                                              ) : (
                                                note
                                        && note.attachments
                                        && note.attachments.map((attachment, index) => (
                                          attachment && (
                                            <>
                                              <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={(event) => downloadTaskAttachment(event, attachment)}
                                                key={index}
                                                className={classes.iconGroup}
                                              >
                                                <Attachment />
                                                <Typography variant="body2">
                                                  {attachment.name}
                                                </Typography>
                                              </div>
                                            </>
                                          )
                                        ))
                                              )}
                                          </Typography>
                                        </>
          )}
                                    />
                                  </ListItem>
                                  <hr className={classes.divider} />
                                </div>
                              ))
                        )}
                    </List>
                  </div>
                </Card>
              </Box>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}

export default TaskDetails;
