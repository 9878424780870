import {
  GET_ATTACHMENT_URL, GET_TASK_COMMENT, GET_TASK_DETAILS, GET_TASK_ID, GET_TASKS
} from '../actions/tasksActions';

const initialState = {
  tasks: [],
  taskDetails: {},
  taskId: 0,
  attachmentURL: '',
  comment: ''
};

const TasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASKS:
      return {
        ...state,
        tasks: action.payload
      };
    case GET_TASK_DETAILS:
      return {
        ...state,
        taskDetails: action.payload
      };
    case GET_TASK_ID:
      return {
        ...state,
        taskId: action.payload
      };
    case GET_ATTACHMENT_URL:
      return {
        ...state,
        attachmentURL: action.payload
      };
    case GET_TASK_COMMENT:
      return {
        ...state,
        comment: action.payload
      };
    default:
      return state;
  }
};

export default TasksReducer;
