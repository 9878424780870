export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT_TASKS = 'GET_PROJECT_TASKS';
export const GET_PROJECT_ID = 'GET_PROJECT_ID';
export const GET_PROJECT_TASKS_STATE = 'GET_PROJECT_TASKS_STATE';

export const saveProjects = (projects) => ({
  type: GET_PROJECTS,
  payload: projects,
});

export const saveProjectTasks = (projectTasks) => ({
  type: GET_PROJECT_TASKS,
  payload: projectTasks,
});

export const saveSelectedProjectId = (projectId) => ({
  type: GET_PROJECT_ID,
  payload: projectId,
});

export const saveSelectedTaskState = (state) => ({
  type: GET_PROJECT_TASKS_STATE,
  payload: state,
});
